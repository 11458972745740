/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import ".././src/assets/scss/variables.scss";
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #09070e;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.title {
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: #ccc;
  opacity: 0.4;
  margin-bottom: 0px;
}

.profleBtn {
  border-radius: 4px;
  background: var(--CTA-BG, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  color: #31313f;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 10px;
  border-radius: 6px;
  margin-top: 6px;
  border: 0px;
  margin-left: 86px;
}

.tab-list {
  display: inline-flex;
  border-radius: 4px;
  .item {
    color: var(--white-100);
    font-size: 15px;
    font-weight: 300;
    line-height: normal;
    padding: 10px 30px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid var(--dark-6);
    text-wrap: nowrap;
    &.active {
      color: var(--dark-4);
      background: var(--dark-6);
      font-weight: 500;
    }
  }
  &.full {
    width: 100%;
    .item {
      width: 50%;
      text-align: center;
      &.active {
        border-bottom: 2px solid;
      }
    }
  }
}

.loginProfileLIst {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.loginProfileLIst li {
  width: 100%;
  text-align: left;
  padding-bottom: 8px;
}

.loginProfileLIst li button {
  margin: 0px;
  background: none;
  border: 0px;
  width: 100%;
  margin: 0px !important;
  text-align: left;
  color: #ebebeb;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.loginProfileLIst li button span {
  padding-left: 10px;
}

.matmenustyle {
  overflow: inherit;
  border: 0px;
}

.cursorpointer {
  cursor: pointer;
}

.lms-checked-toggle-btn {
  margin-right: 15px;

  label {
    border-radius: 12px;
    background: #22222f;

    color: #fff;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 20px;

    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid transparent;
    background: linear-gradient(180deg, #22222f 0%, #22222f 100%),
      radial-gradient(circle at top right, #27d4fa, #7af4fc);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    span {
      color: #49e1fb;
      opacity: 1;
    }
  }
}

.lms-radio-toggle-btn {
  margin-right: 15px;
  position: relative;
  label {
    border-radius: 12px;
    background: #22222f;
    color: #fff;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 20px;
    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid #49e1fb;
    span {
      color: #49e1fb;
      opacity: 1;
    }
  }
}
/** Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #09070e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191924;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #222230 0%, #22222d 100%);
}
.ef-lms-btn {
  border-radius: 9px;
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  // background: var(--ctabg, linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%));

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 0px;
  padding: 10px 20px;

  &:hover {
    color: initial;
  }

  &:disabled {
    color: #fff;
    border: 0;
    background: #22222f;

    &span {
      opacity: 0.4;
    }
  }

  &.lms-btn-link {
    /* background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text; */
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    z-index: 1;
    color: #49e1fb;
    background: transparent;
  }

  &.lms-btn-link.danger {
    color: #fa2739;
  }
}
.button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid;
  background: var(--BG, #09070e);
  &.primary {
    color: var(--dark-4);
    background: var(--dark-1);
    &:hover,
    &:visited {
      background: var(--gradient-1);
      color: var(--dark-1);
    }
  }
  &.secondary {
    background: var(--gradient-1);
    color: var(--dark-1);
  }
  &.inline-button {
    padding: 8px 12px;
    width: auto;
  }
  &.default {
    color: #96a7af;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border: none;
    background: none;
  }
  &.theme-button {
    background: none;
    border: none;
    outline: none;
    color: var(--dark-4);
    width: auto;
    padding: 0;
  }
}
button {
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
.assessment-submit-modal .modal-container {
  border-radius: 16px;
  border-bottom: 0px;
}

.table thead tr th {
  border-left: 1px solid #000 !important;
  font-weight: 300 !important;
}

.table tbody tr td {
  border-left: 1px solid #000 !important;
}
.opacity-07 {
  opacity: 0.7;
}
.disabled-button {
  opacity: 0.5;
  cursor: default;
}
